html {
  font-size: 14px;
}

h2, h3, h4, h5, h6 {
  font-weight: normal;
}

h1 {
  text-align: center;
  font-weight: normal;
  margin: 0;
}

h2 {
  text-align: center;
  margin-top: 0;
  margin-bottom: 14px;
}

h3 {
  font-size: 18px;
}