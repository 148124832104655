.sidebar-main,
.sidebar-second,
.sidebar-top,
.module-controls-container,
.module-code-controls,
.modal-controls
{
  a,
  button,
  .item,
  .subnav-item,
  .btn-code-control,
  .btn-module{
    transition: all 0.2s ease;
  }
}