.sidebar-main {
  left: 0;
  top: 0;
  position: fixed;
  height: 100%;
  width: 250px;
  z-index: 99;
  background: $main-sidebar-bg-color;
  color: $main-sidebar-color;
  .sidebar-header {
    background: $logo-bg-color;
    padding: 20px 5px;
    color: $logo-color;
    a {
      color: $logo-color;
      text-decoration: none;
    }
    .logo-container {
      display: block;
      overflow: hidden;
      .logo {
        max-height: 48px;
        width: auto;
        display: block;
        float: left;
        margin-right: 5px;
      }
      .title-container {
        display: block;
        float: left;
        padding-top: 5px;
        .title {
          font-size: 16px;
          font-family: 'Roboto', sans-serif;
          font-weight: 700;
          letter-spacing: 1.1px;
          display: block;
        }
        .subtitle {
          font-size: 12px;
          font-family: 'Roboto', sans-serif;
          font-weight: 300;
          letter-spacing: 1.1px;
          display: block;
        }
      }
    }
  }
  .nav {
    padding: 35px 0px 0px 0px;
    margin: 0;
    list-style: none;
    overflow: auto;
    height: 100%;
    a {
      color: $nav-item-color;
      text-decoration: none;
    }
    .nav-item {
      display: block;
      font-family: 'Roboto', sans-serif;
      text-transform: uppercase;
      cursor: pointer;
      padding: 0px;
      a:hover, &.active, &:hover {
        color: $nav-item-active-color;
      }
      .item {
        display: block;
        padding: 15px 0px 8px 20px;
        .icon {
          padding-right: 5px;
        }
      }
      .subnav {
        display: none;
        margin: 0;
        padding: 0;
        list-style: none;
        text-transform: none;
        .subnav-item {
          border-left: 4px solid $subnav-item-border-color;
          color: $subnav-item-color;
          cursor: pointer;
          padding: 12px 0px;
          padding-left: 40px;
          a:hover, &:hover {
            color: $subnav-item-active-color;
          }
          &.active {
            color: $subnav-item-active-color;
            background: $subnav-item-active-bg-color;
            border-color: $subnav-item-border-active-color;
          }
        }
      }
    }
  }
  .sidebar-footer {
    position: absolute;
    bottom: 0px;
    left: 0px;
    padding: 20px;
    .btn-main {
      width: 100%;
      text-align: center;
      text-decoration: none;
      display: block;
      padding: 10px 20px;
      color: $button-main-color;
      background: $button-main-bg-color;
      margin-bottom: 10px;

      &:hover{
        color: $button-main-active-color;
        background: $button-main-active-bg-color;
      }
    }
    .btn-second {
      width: 100%;
      text-align: center;
      text-decoration: none;
      display: block;
      padding: 10px 20px;
      color: $button-secondary-color;
      background: $button-secondary-bg-color;

      &:hover{
        color: $button-secondary-active-color;
        background: $button-secondary-active-bg-color;
      }
    }
  }
}